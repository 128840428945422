import { Component, OnInit } from '@angular/core';
import {tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/appState';
import {Observable} from 'rxjs';
import * as projects from '../../data/projects.json';
import {storeActions} from '../../../../store/store.actions';

@Component({
  selector: 'app-project-page',
  templateUrl: './project-page.component.html',
  styleUrls: ['./project-page.component.scss'],
  host: {'style': 'width:100%;'}
})
export class ProjectPageComponent implements OnInit {
  public pageId: number;
  public projectsData;
  public currentProject;
  public redirect$: Observable<any>;

  constructor(private store: Store<{myStore: AppState}>) {
    this.projectsData = new Map();

    (projects as any).default.forEach((value) => {
      this.projectsData.set(value.id, value);
    });
  }

  ngOnInit(): void {
    this.setObservers();
  }

  loadPage(pageId){
    this.pageId = pageId;
    this.currentProject = this.projectsData.get(pageId);
  }

  nextPage(currentPageId: number) {
    var newPageId = currentPageId + 1;
    if ((newPageId) > this.projectsData.size) {
      newPageId = 1;
    }
    this.store.dispatch({
      type: storeActions.REDIRECT,
      page: 'project',
      projectId: newPageId,
    });
  }

  prevPage(currentPageId: number) {
    var newPageId = currentPageId - 1;
    if ((newPageId) === 0) {
      newPageId = this.projectsData.size;
    }
    this.store.dispatch({
      type: storeActions.REDIRECT,
      page: 'project',
      projectId: newPageId,
    });
  }

  private setObservers() {
    this.redirect$ = this.store.select('myStore', 'projectId').pipe(tap(data => this.loadPage(data)));

    this.redirect$.subscribe();
  }
}
