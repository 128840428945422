import { Component } from '@angular/core';
import { AudioService } from '../../../services/audio.service';
import { CloudService } from '../../../services/cloud.service';
import { StreamState } from '../../../interfaces/stream-state';
import { trigger, transition, animate, style } from '@angular/animations'

@Component({
  selector: 'app-player-mobile',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({height: 0, opacity: 1}),
        animate('200ms ease-in', style({height: '200px', opacity: 1}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({height: 0, opacity: 1}))
      ])
    ])
  ]
})
export class PlayerMobileComponent {
  files: Array<any> = [];
  state: StreamState;
  currentFile: any = {};
  nameIterations: any = new Array(20);
  playlistVisible = false;
  isFirstPlay = true;
  playerVisible = true;

  constructor(private audioService: AudioService, cloudService: CloudService) {
    // get media files
    cloudService.getFiles().subscribe(files => {
      this.files = files;
      const file = files[0];
      const index = 0;
      this.currentFile = { index, file };
    });

    // listen to stream state
    this.audioService.getState()
      .subscribe(state => {
        this.state = state;
        console.log('play', state);
      });
  }

  showPlaylist(): void {
    this.playlistVisible = true;
  }

  hidePlaylist(): void {
    this.playlistVisible = false;
  }

  playStream(url): void {
    this.audioService.playStream(url)
      .subscribe(events => {
        // listening for fun here
        console.log('playstream', events);
        // @ts-ignore
        if (events?.type === 'ended') {
          this.next();
        }
      });
  }

  openFile(file, index): void {
    this.currentFile = { index, file };
    this.audioService.stop();
    this.playStream(file.url);
    console.log('openfile');
  }

  pause(): void {
    this.audioService.pause();
  }

  play(): void {
    if (this.isFirstPlay) {
      this.openFile(this.files[0], 0);
      this.isFirstPlay = false;
      return;
    }

    this.audioService.play();
  }

  stop(): void {
    this.audioService.stop();
  }

  mute(): void {
    this.audioService.mute();
  }

  unmute(): void {
    this.audioService.unmute();
  }

  next(): void {
    let index = this.currentFile.index + 1;
    if (this.files.length <= index) {
      index = 0;
    }
    const file = this.files[index];
    this.openFile(file, index);
  }

  previous(): void {
    const index = this.currentFile.index - 1;
    const file = this.files[index];
    this.openFile(file, index);
  }

  isFirstPlaying(): boolean {
    return this.currentFile.index === 0;
  }

  isLastPlaying(): boolean {
    return this.currentFile.index === this.files.length - 1;
  }

  onSliderChangeEnd(change): void {
    this.audioService.seekTo(change.value);
  }

  closePlayer(): void {
    this.playerVisible = false;
  }

  openPlayer(): void {
    this.playerVisible = true;
  }
}
