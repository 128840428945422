<div class="website"
     [ngClass]="{'inverted': hasInvertedClass}"
     >
  <app-navbar id="navbar"></app-navbar>
<!--  <div class="content-wrapper" [ngClass]="{'blured': hasBluredClass}" [ngStyle]="{'height': height}">-->
<!--    <ng-template contentHost #content></ng-template>-->
<!--  </div>-->
<!--  <div id="footer" [ngClass]="{'hidden-bio': isBioHidden}">-->
<!--    <div class="footer-column" id="first-column">-->
<!--      <app-avatar class="avatar"></app-avatar>-->
<!--      <app-button [actionType]="buttonAction" [content]="buttonContent"></app-button>-->
<!--    </div>-->
<!--    <div class="footer-column" id="second-column" [ngClass]="{'top-border': isProjectPage}">-->
<!--&lt;!&ndash;      <app-player></app-player>&ndash;&gt;-->
<!--    </div>-->
<!--    <div class="footer-column" id="third-column">-->
<!--      <app-bio class="bio"></app-bio>-->
<!--        <app-social-links class="social-links" [borderTop]="true"></app-social-links>-->
<!--    </div>-->
<!--  </div>-->

  <div class="mobile-content-wrapper" id="homemobile">
    <div class="mobile-player-wrapper">
      <app-player-mobile></app-player-mobile>
    </div>
    <div class="mobile-content mobile-placeholder"></div>
    <div class="bio-mobile mobile-content" id="biomobile" style="position: relative;">
      <div id="biomobile-anchor" style="position: absolute; top: -100px; left: 0"></div>
      <div class="gallery">
        <img src="assets/gallery/mobile/1.png">
        <img src="assets/gallery/mobile/2.jpg">
        <img src="assets/gallery/mobile/3.jpg">
        <img src="assets/gallery/mobile/4.jpg">
        <img src="assets/gallery/mobile/5.jpg">
        <img src="assets/gallery/mobile/6.jpg">
        <img src="assets/gallery/mobile/7.jpg">
        <img src="assets/gallery/mobile/8.jpg">
        <img src="assets/gallery/mobile/9.jpg">
      </div>
      <div class="text">Steven Bakes is DJ and music producer based in Oslo, Norway. He loves having fun on the stage while playing a great variety of different music. All from MK inspired Tech House to Bee Gees 70’s disco. His family came from Ghana and therefore African origins play an important role in his creative process. You are guaranteed a good time with Steven on the stage </div>
    </div>
    <div class="projects-mobile mobile-content" id="projectsmobile" style="position: relative;">
      <div id="projectsmobile-anchor" style="position: absolute; top: -100px; left: 0"></div>
      <div class="recent">PROJECTS</div>
      <div class="recent date">2018-{{year}}</div>
      <span *ngFor="let project of projectsData">
        <div class="project-page-mobile {{ project.mobileClass }}" *ngIf="project.showInMenu">
          <div class="header">
            <div>{{ project.year }}</div>
            <div>{{ project.status }}</div>
          </div>

          <div class="title">
            {{ project.mobileName }}
          </div>

          <div class="button">
            <app-button [actionType]="showProjectAction" [project]="project.id" [content]="'MORE'" [isMobile]="true"></app-button>
          </div>
        </div>
        <span *ngIf="currentProject===project.id" class="project-page-mobile-wrapper" style="z-index: 998;">
          <div class="project-page-mobile details">
            <span class="header-wrapper">
              <div class="title">
                {{ project.mobileName }}
              </div>

              <div class="header">
                <div>{{ project.year }}</div>
                <div>{{ project.status }}</div>
              </div>
            </span>
            <div class="cta-wrapper" style="background-image: url({{'assets/' + project.img}})">
              <div class="button">
                <app-button [actionType]="buttonAction" [content]="'MUSIC + VIDEO'" [isMobile]="true"></app-button>
              </div>
            </div>
            <div class="description-wrapper">
              <span [innerHTML]="project.firstColumnText"></span>
              <div class="back-button">
                <app-button [actionType]="hideProjectAction" [content]="'BACK'" [isMobile]="true"></app-button>
              </div>
            </div>
          </div>
        </span>
      </span>
<!--      <div class="show-all">-->
<!--        <div class="title">-->
<!--          FULL LIST OF PROJECTS-->
<!--        </div>-->
<!--        <div class="button">-->
<!--          <app-button [actionType]="buttonAction" [content]="'CHECK OUT'" [isMobile]="true"></app-button>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="music-mobile mobile-content" style="margin-top: -60px;" id="musicmobile" style="position: relative;">
      <div id="musicmobile-anchor" style="position: absolute; top: -100px; left: 0"></div>

      <div class="stream">STREAM<br>MUSIC</div>
      <div class="stream produced">PRODUCED BY<br>STEVEN BAKES</div>
<!--      <img src="assets/music/google_play_music.png">-->
<!--      <img src="assets/music/apple_music.png">-->
      <a href="https://open.spotify.com/artist/0NjlKoEAt6bcWuBxslxMKn?si=uVq8sXCBSaaOlxLPmnsXyg&nd=1"><img src="assets/music/spotify.png"></a>
    </div>
    <div class="supl-back mobile-content">
      <img src="assets/supl_back.png">
    </div>
    <div class="contact-mobile mobile-content" id="contactmobile" style="position: relative;">
      <div id="contactmobile-anchor" style="position: absolute; top: -100px; left: 0"></div>

      <div class="keep-in-touch">KEEP<br>IN TOUCH</div>
      <div class="keep-in-touch collaborations">FOR COLLABORATIONS<br>AND INQUIRIES</div>
      <div class="details-wrapper">
        <div class="label">CALL</div>
        <div class="value">960 17 791<br>993 23 235</div>
      </div>
      <div class="details-wrapper">
        <div class="label">EMAIL</div>
        <div class="value">stevenbakesmusic@gmail.com</div>
      </div>
      <div class="details-wrapper" style="margin-bottom: 80px">
        <div class="label">FOLLOW</div>
        <div class="value"><a href='https://www.instagram.com/st3phenofficial/' >@ST3PHENOFFICIAL</a></div>
      </div>
      <app-social-links [borderTop]="false" class="social-links-mobile"></app-social-links>
      <div class="year">{{year}}</div>
    </div>
  </div>
</div>


