<nav id="project-navbar">
  <span id="hamburger">
    {{currentProject.year}}
  </span>
  <div class="center-column-wrapper">
    <img src="assets/arrow-left.svg" (click)="prevPage(currentProject.id)">
    {{currentProject.name}}
    <img src="assets/arrow-right.svg" (click)="nextPage(currentProject.id)">
  </div>
  <span class="label">{{currentProject.type}}</span>
</nav>

<div id="project-content">
  <div id="content-box">
  <div class="column-wrapper">
    <div class="column">{{currentProject.firstColumnText}}</div>
    <div class="column">{{currentProject.secondColumnText}}</div>
  </div>
  </div>
  <div id="photo-box" style="background-image: url({{'assets/' + currentProject.img}})"></div>
</div>
