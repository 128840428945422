import { Component } from '@angular/core';
import { AudioService } from '../../../services/audio.service';
import { CloudService } from '../../../services/cloud.service';
import { StreamState } from '../../../interfaces/stream-state';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent {
  files: Array<any> = [];
  state: StreamState;
  currentFile: any = {};

  constructor(private audioService: AudioService, cloudService: CloudService) {
    // get media files
    cloudService.getFiles().subscribe(files => {
      this.files = files;
    });

    // listen to stream state
    this.audioService.getState()
      .subscribe(state => {
        this.state = state;
      });
  }

  playStream(url): void {
    this.audioService.playStream(url)
      .subscribe(events => {
        // listening for fun here
      });
  }

  openFile(file, index): void {
    this.currentFile = { index, file };
    this.audioService.stop();
    this.playStream(file.url);
  }

  pause(): void {
    this.audioService.pause();
  }

  play(): void {
    this.audioService.play();
  }

  stop(): void {
    this.audioService.stop();
  }

  next(): void {
    const index = this.currentFile.index + 1;
    const file = this.files[index];
    this.openFile(file, index);
  }

  previous(): void {
    const index = this.currentFile.index - 1;
    const file = this.files[index];
    this.openFile(file, index);
  }

  isFirstPlaying(): boolean {
    return this.currentFile.index === 0;
  }

  isLastPlaying(): boolean {
    return this.currentFile.index === this.files.length - 1;
  }

  onSliderChangeEnd(change): void {
    this.audioService.seekTo(change.value);
  }
}
