import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver, DoCheck,
  HostListener,
  OnInit,
  ViewChild
} from '@angular/core';
import {ContentDirective} from './content.directive';
import {ContactComponent} from './contact/contact.component';
import {ProjectsComponent} from './projects/projects.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ShopComponent} from './shop/shop.component';
import {MusicComponent} from './music/music.component';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {blur, storeActions} from '../../store/store.actions';
import {AppState} from '../../store/appState';
import {NgxSpinnerService} from 'ngx-spinner';
import {ProjectPageComponent} from './projects/project-page/project-page.component';
import {GalleryComponent} from './gallery/gallery.component';
import {ButtonActionType} from '../../enums/button-action-type';
import * as projects from './data/projects.json';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss']
})
export class WebsiteComponent implements OnInit, AfterViewInit {
  public hasInvertedClass = false;
  public hasBluredClass: boolean = false;
  public height = '';
  public navHeight: number = 101;
  public footerHeight: number = 0;
  public bioHeight: number = 0;
  private component: any = DashboardComponent;
  public myStore: Observable<any>;
  public buttonActionType: any = ButtonActionType;
  public buttonAction: ButtonActionType = ButtonActionType.hideBio;
  public showProjectAction: ButtonActionType = ButtonActionType.showMobileProject;
  public hideProjectAction: ButtonActionType = ButtonActionType.hideMobileProject;
  public buttonContent: string = 'HIDE BIO';
  public isBioHidden: boolean;
  public isProjectPage: boolean;
  public year: number = (new Date()).getFullYear();
  public projectsData = (projects as any).default;
  public currentProject = 0;

  public isInverted$: Observable<any>;
  public isBlured$: Observable<any>;
  public navHeight$: Observable<any>;
  public footerHeight$: Observable<any>;
  public redirect$: Observable<any>;
  public hideBio$: Observable<any>;
  private showMobileProject$: Observable<any>;
  private hideMobileProject$: Observable<any>;

  @ViewChild(ContentDirective, {static: true}) contentHost: ContentDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private store: Store<{ myStore: AppState }>, private cdr: ChangeDetectorRef, private spinner: NgxSpinnerService, private sanitizer: DomSanitizer) {
    this.myStore = store.select('myStore');
    // this.spinner.show();
  }

  ngOnInit(): void {
    // console.log(document.getElementById('navbar').offsetHeight);

    this.setObservers();
    this.reloadPage();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 3000);

    setTimeout(() => {
      if (document.getElementById('footer').offsetHeight !== this.footerHeight) {
        this.store.dispatch({
          type: storeActions.CHANGE_FOOTER_HEIGHT,
          height: document.getElementById('footer').offsetHeight
        });
      }

      if (document.getElementById('nav-navbar').offsetHeight !== this.navHeight) {
        this.store.dispatch({
          type: storeActions.CHANGE_NAV_HEIGHT,
          height: document.getElementById('nav-navbar').offsetHeight
        });
      }
    }, 1000);
  }

  // tslint:disable-next-line:typedef
  public navigate(valueEmitted) {
    if(valueEmitted !== '') {
    if ((document.querySelectorAll('.mobile-content-wrapper'))[0].scrollHeight > 0) {
      switch (valueEmitted) {
        case 'home':
          (document.getElementById('homemobile') as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'start' });
          break;
        case 'projects':
          (document.getElementById('projectsmobile-anchor') as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'start' });
          break;
        case 'bio':
          (document.getElementById('biomobile-anchor') as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'start' });
          break;
        case 'music':
          (document.getElementById('musicmobile-anchor') as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'start' });
          break;
        case 'contact':
          (document.getElementById('contactmobile-anchor') as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'start' });
          break;
        // case 'project':
        //   this.component = ProjectPageComponent;
        //   this.isProjectPage=true;
        //   break;
      }

      this.store.dispatch({
        type: storeActions.HIDE_MOBILE_PROJECT,
      });

      this.store.dispatch({
        type: storeActions.CLEAR_CURRENT_PAGE,
      });
    }
    this.isProjectPage = false;
    switch (valueEmitted) {
      case 'projects':
        this.component = ProjectsComponent;
        this.isProjectPage = true;
        break;
      case 'shop':
        this.component = ShopComponent;
        break;
      case 'music':
        this.component = MusicComponent;
        break;
      case 'contact':
        this.component = ContactComponent;
        break;
      case 'project':
        this.component = ProjectPageComponent;
        this.isProjectPage = true;
        break;
      case 'gallery':
        this.component = GalleryComponent;
        break;
    }
     // this.reloadPage();
    }
  }

  private reloadPage() {
    const component = this.component;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    const viewContainerRef = this.contentHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<typeof component>(componentFactory);
  }

  private hideBio(hide) {
    let multiplier = 1;
    if (hide) {
      this.bioHeight = Math.floor(document.getElementById('footer').offsetHeight) - Math.floor(document.getElementById('social-links-wrapper').offsetHeight);
      this.isBioHidden = true;
      this.buttonAction = ButtonActionType.showBio;
      this.buttonContent = 'SHOW BIO';
      multiplier = -1;
    } else {
      this.isBioHidden = false;
      this.buttonAction = ButtonActionType.hideBio;
      this.buttonContent = 'HIDE BIO';
    }

    console.log('footer', document.getElementById('footer').offsetHeight);
    console.log('social', document.getElementById('social-links-wrapper').offsetHeight);
    console.log('bio', this.bioHeight);

    this.store.dispatch({
      type: storeActions.CHANGE_FOOTER_HEIGHT,
      height: (document.getElementById('footer').offsetHeight + this.bioHeight * multiplier)
    });
  }

  private showMobileProject(projectId) {
    // (document.querySelector('[data-project="' + projectId + '"]') as HTMLElement).style.display = 'flex';
  }

  private hideMobileProject() {
    // (document.querySelector('.project-page-mobile.details') as HTMLElement).style.display = 'none';
  }

  private setObservers() {
    this.isBlured$ = this.store.select('myStore', 'blur').pipe(tap(data => this.hasBluredClass = data));
    this.isInverted$ = this.store.select('myStore', 'isInverted').pipe(tap(data => this.hasInvertedClass = data));
    this.navHeight$ = this.store.select('myStore', 'navHeight').pipe(tap(data => {
      this.navHeight = data.height;
      this.reloadHeight(null);
    }));
    this.footerHeight$ = this.store.select('myStore', 'footerHeight').pipe(tap(data => {
      this.footerHeight = data.height;
      this.reloadHeight(null);
    }));
    this.redirect$ = this.store.select('myStore', 'currentPage').pipe(tap(data => this.navigate(data)));
    this.hideBio$ = this.store.select('myStore', 'bioHidden').pipe(tap(data => this.hideBio(data)));

    this.showMobileProject$ = this.store.select('myStore', 'mobileProjectId').pipe(tap(data => {
      this.currentProject = data.project;
    }));

    this.isBlured$.subscribe();
    this.isInverted$.subscribe();
    this.navHeight$.subscribe();
    this.footerHeight$.subscribe();
    this.redirect$.subscribe();
    this.hideBio$.subscribe();
    this.showMobileProject$.subscribe();
  }

  @HostListener('window:resize', ['$event'])
  private reloadHeight(event): void {
    const innerHeight = window.innerHeight;
    const footerHeight = this.footerHeight;
    const navHeight = this.navHeight;
    const elementHeight = innerHeight - footerHeight - navHeight;

    // console.log(innerHeight, footerHeight, navHeight, elementHeight);
    this.height = elementHeight + 'px';
  }
}
