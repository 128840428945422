import {ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Store} from '@ngrx/store';
import {AppState} from './store/appState';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'steven-bakes';

  constructor(private spinner: NgxSpinnerService) {
    this.spinner.show();
  }

  ngOnInit(): void {
    setTimeout(() => document.getElementById('overlay').style.display = 'none', 1000);
  }
}
