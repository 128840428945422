import {Component, Input, OnInit} from '@angular/core';
import {ButtonActionType} from '../../../enums/button-action-type';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/appState';
import {storeActions} from '../../../store/store.actions';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() content: string;
  @Input() actionType: ButtonActionType;
  @Input() isMobile = false;
  @Input() project = 0;

  constructor(private store: Store<{myStore: AppState}>) {
  }

  ngOnInit(): void {
  }

  handleClick() {
    switch (this.actionType) {
      case ButtonActionType.hideBio:
        this.store.dispatch({
          type: storeActions.HIDE_BIO
        });
        break;
      case ButtonActionType.showBio:
        this.store.dispatch({
          type: storeActions.SHOW_BIO
        });
        break;
      case ButtonActionType.showMobileProject:
        this.store.dispatch({
          type: storeActions.SHOW_MOBILE_PROJECT,
          project: this.project
        });
        break;
      case ButtonActionType.hideMobileProject:
        this.store.dispatch({
          type: storeActions.HIDE_MOBILE_PROJECT
        });
        break;
      default:
        break;
    }
  }
}
