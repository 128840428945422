<div class="container">
  <!--  <mat-toolbar color="primary" class="main-toolbar">-->
  <!--    <span>Audio Player</span>-->
  <!--  </mat-toolbar>-->
  <!--    <div class="content">-->
  <!--      <div class="logo">-->
  <!--        <mat-icon>music_note</mat-icon>-->
  <!--        <div>Audio Player</div>-->
  <!--      </div>-->
  <!--      <mat-list color="primary">-->
  <!--        <h3 mat-subheader>Songs</h3>-->
  <!--        <mat-list-item *ngFor="let file of files; let i = index" (click)="openFile(file, i)">-->
  <!--          <mat-icon color="primary" mat-list-icon>music_note</mat-icon>-->
  <!--          <h4 mat-line>{{ file.name }}</h4>-->
  <!--          <h5 mat-line>by {{ file.artist }}</h5>-->
  <!--          <mat-icon color="primary" *ngIf="currentFile.index === i && !state?.error">volume_up</mat-icon>-->
  <!--          <h6 *ngIf="currentFile.index === i && state?.error">ERROR</h6>-->
  <!--          <mat-divider></mat-divider>-->
  <!--        </mat-list-item>-->
  <!--      </mat-list>-->
  <!--    </div>-->
  <!--    <div class="media-footer">-->
  <!--      <mat-toolbar color="primary">-->
  <!--        <mat-toolbar-row>-->
  <!--          {{ state?.readableCurrentTime }}-->
  <!--          <mat-slider class="time-slider" min="0" [max]="state?.duration" step="1" [value]="state?.currentTime" (input)="onSliderChangeEnd($event)" [disabled]="state?.error || currentFile.index === undefined"></mat-slider>-->
  <!--          {{ state?.readableDuration }}-->
  <!--        </mat-toolbar-row>-->
  <!--        <mat-toolbar-row class="media-action-bar">-->
  <!--          <button mat-button [disabled]="isFirstPlaying()" (click)="previous()">-->
  <!--            <mat-icon mat-list-icon>skip_previous</mat-icon>-->
  <!--          </button>-->
  <!--          <button mat-button (click)="play()" [disabled]="state?.error" *ngIf="!state?.playing">-->
  <!--            <mat-icon mat-list-icon>play_circle_filled</mat-icon>-->
  <!--          </button>-->
  <!--          <button mat-button (click)="pause()" *ngIf="state?.playing">-->
  <!--            <mat-icon mat-list-icon>pause</mat-icon>-->
  <!--          </button>-->
  <!--          <button  mat-button [disabled]="isLastPlaying()" (click)="next()">-->
  <!--            <mat-icon mat-list-icon>skip_next</mat-icon>-->
  <!--          </button>-->
  <!--        </mat-toolbar-row>-->
  <!--      </mat-toolbar>-->
  <!--  </div>-->
  <div id="player-playlist" *ngIf="playerVisible">
    <div *ngIf="!playlistVisible" class="header" (click)="showPlaylist()">
      CLICK TO OPEN PLAYLIST
    </div>
    <div *ngIf="playlistVisible" class="header" (click)="hidePlaylist()">
      CLICK TO HIDE PLAYLIST
    </div>
    <div *ngIf="playlistVisible" class="playlist" [@fadeInOut]>
        <h3>STEVEN BAKES PLAYLIST</h3>
        <div *ngFor="let file of files; let i = index" (click)="openFile(file, i)" class="song" [ngClass]="{'running': currentFile.index === i && !state?.error}">
          <span class="no">{{ i + 1 }}</span>
          <span class="songname">{{ file.name }}</span>
          <span class="runtime">{{ file.runtime }}</span>
        </div>

    </div>
  </div>
  <div id="player-songname" *ngIf="playerVisible">
    <ng-marquee speed="slow">
      &nbsp;&nbsp;<span style="margin-right: 20px; line-height: 30px" *ngFor="let number of nameIterations">{{ currentFile?.file?.name }}</span>
    </ng-marquee>
  </div>
  <div id="player-panel">
    <div class="close-button">
      <button mat-button class="close" (click)="closePlayer()" [disabled]="state?.error" *ngIf="playerVisible">
        <img src="assets/big_x.svg">
      </button>
      <button mat-button class="open" (click)="openPlayer()" [disabled]="state?.error" *ngIf="!playerVisible" [ngClass]="{'transparent': !playerVisible}">
        <mat-icon mat-list-icon>play_arrow</mat-icon>
      </button>
    </div>
    <div class="play-button" [ngClass]="{'invisible': !playerVisible}">
      <button mat-button class="play" (click)="play()" [disabled]="state?.error" *ngIf="!state?.playing">
        <mat-icon mat-list-icon>play_arrow</mat-icon>
      </button>
      <button mat-button class="pause" (click)="pause()" *ngIf="state?.playing">
        <mat-icon mat-list-icon>pause</mat-icon>
      </button>
    </div>
    <div class="volume-button" [ngClass]="{'invisible': !playerVisible}">
      <button mat-button class="mute" (click)="mute()" *ngIf="!state?.mute">
        <mat-icon mat-list-icon>volume_up</mat-icon>
      </button>
      <button mat-button class="unmute" (click)="unmute()" *ngIf="state?.mute">
        <mat-icon mat-list-icon>volume_off</mat-icon>
      </button>
    </div>
  </div>
</div>
