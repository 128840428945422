import {Component, Input, OnInit} from '@angular/core';
import {tap} from 'rxjs/operators';
import {AppState} from '../../../../store/appState';
import {DashboardComponent} from '../../dashboard/dashboard.component';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {storeActions} from '../../../../store/store.actions';

@Component({
  selector: 'app-project-thumbnail',
  templateUrl: './project-thumbnail.component.html',
  styleUrls: ['./project-thumbnail.component.scss']
})
export class ProjectThumbnailComponent implements OnInit {
  @Input() year: string;
  @Input() status: string;
  @Input() info: string;
  @Input() type: string;
  @Input() img: string;
  @Input() id: number;

  public hasBluredClass: boolean = false;

  public isBlured$: Observable<any>;

  constructor(private store: Store<{myStore: AppState}>) { }

  ngOnInit(): void {
    this.setObservers();
  }

  openProject(projectId): void {
    this.store.dispatch({
      type: storeActions.REDIRECT,
      page: 'project',
      projectId,
    });
  }

  private setObservers() {
    this.isBlured$ = this.store.select('myStore', 'blur').pipe(tap(data => this.hasBluredClass = data));

    this.isBlured$.subscribe();
  }
}
