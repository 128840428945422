import {Component, Input, OnInit} from '@angular/core';
import {storeActions} from '../../../store/store.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/appState';

@Component({
  selector: 'app-bio',
  templateUrl: './bio.component.html',
  styleUrls: ['./bio.component.scss']
})
export class BioComponent implements OnInit {

  constructor(private store: Store<{myStore: AppState}>) { }

  ngOnInit(): void {
  }

  hideBio() {
    this.store.dispatch({
      type: storeActions.HIDE_BIO
    });
  }
}
