import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  host: {'style': 'width:100%;display:flex;'}
})
export class ContactComponent implements OnInit {
  phoneNumber: string = '96 01 77 91';
  email: string = 'info@stevenbakesmusic.no';

  constructor() { }

  ngOnInit(): void {

  }

}
