import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {WebsiteComponent} from './pages/website/website.component';
import {ContactComponent} from './pages/website/contact/contact.component';
import {ProjectThumbnailComponent} from './pages/website/projects/project-thumbnail/project-thumbnail.component';
import {CommonModule} from '@angular/common';


const routes: Routes = [
  {path: '', component: WebsiteComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'thumb', component: ProjectThumbnailComponent}
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
