import { createReducer, on } from '@ngrx/store';
import {
  blur,
  changeFooterHeight,
  changeNavHeight, clearCurrentPage,
  hideBio, hideMobileProject,
  invertColors,
  makeRedirect,
  showBio,
  showMobileProject,
  unblur
} from './store.actions';
import {AppState} from './appState';


export const initialState: AppState = {
  blur: false,
  isInverted: false,
  navHeight: 0,
  footerHeight: 0,
  currentPage: 'dashboard',
  projectId: 0,
  bioHidden: false,
  isMobileProjectHidden: true,
  mobileProjectId: 0,
};

const _storeReducer = createReducer(
  initialState,
  on(blur, (state) => blurContent(state, true)),
  on(unblur, (state) => blurContent(state, false)),
  on(invertColors, (state) => invertContent(state)),
  on(changeFooterHeight, (state, height) => changeFooterHeightAction(state, height)),
  on(changeNavHeight, (state, height) => changeNavHeightAction(state, height)),
  on(makeRedirect, (state, action) => redirectAction(state, action)),
  on(clearCurrentPage, (state) => clearCurrentPageAction(state)),
  on(showBio, (state) => hideBioAction(state, false)),
  on(hideBio, (state) => hideBioAction(state, true)),
  on(showMobileProject, (state, project) => showProjectsMobile(state, project)),
  on(hideMobileProject, (state) => hideProjectsMobile(state)),
);

const blurContent = (state, value) => {
  return {...state, blur: value};
};

const invertContent = (state) => {
  return {...state, isInverted: !state.isInverted};
};

const changeNavHeightAction = (state, height) => {
  // console.log(height);
  return {...state, navHeight: height};
}

const changeFooterHeightAction = (state, height) => {
  // console.log(height);
  return {...state, footerHeight: height};
}

const redirectAction = (state, {page, projectId}) => {
  // console.log(page, projectId);
  return {...state, currentPage: page, projectId};
}

const hideBioAction = (state, value) => {
  return {...state, bioHidden: value};
}

const showProjectsMobile = (state, value) => {
  return {...state, isMobileProjectHidden: false, mobileProjectId: value};
}

const hideProjectsMobile = (state) => {
  return {...state, isMobileProjectHidden: true, mobileProjectId: 0};
}

const clearCurrentPageAction = (state) => {
  return {...state, currentPage: ''};
}

export function storeReducer(state, action) {
  return _storeReducer(state, action);
}

