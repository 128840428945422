import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ComponentFactoryResolver,
  AfterViewInit,
  AfterViewChecked,
  ElementRef
} from '@angular/core';
import * as projects from '../data/projects.json';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {blur, storeActions, unblur} from '../../../store/store.actions';
import {AppState} from '../../../store/appState';
import {pluck, tap} from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  host: {'style': 'z-index: 999;'}
})
export class NavbarComponent implements OnInit, AfterViewChecked {
  @Output() navigate = new EventEmitter<string>();
  @Output() isBluredContent = new EventEmitter<boolean>();
  @Output() isInverted = new EventEmitter<boolean>();
  public hasInverted = false;
  public projectsData = (projects as any).default;
  public marqueeWidth = 0;
  public redirect$: Observable<any>;

  constructor(private store: Store<{myStore: AppState}>, private el: ElementRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked() {
  }

  toggleInverted() {
    this.invertColors();
  }

  emitNavEvent(eventType: string) {
    this.store.dispatch({
      type: storeActions.REDIRECT,
      page: eventType,
      projectId: 0,
    });

    this.hideBurgerMenu();
    this.hideSubmenu();
  }

  redirectToProject(projectId: number) {
    this.store.dispatch({
      type: storeActions.REDIRECT,
      page: 'project',
      projectId,
    });

    this.hideBurgerMenu();
    this.hideSubmenu();
  }

  showBurgerMenu() {
    (document.querySelector('.burger-menu')as HTMLElement).style.display = 'flex';
    setTimeout(() => {
      const pointerWidth = document.querySelector('.social-links-pointer').clientWidth-25;
      this.marqueeWidth = pointerWidth - document.querySelector('.social-links-pointer-arrow').clientWidth-2;
      (document.querySelector('.social-links-pointer') as HTMLElement).style.maxWidth = pointerWidth+"px";
    });
  }

  hideBurgerMenu() {
    (document.querySelector('.burger-menu')as HTMLElement).style.display = 'none';
  }

  hideSubmenu() {
    document.getElementById('submenu').style.display = 'none';
    setTimeout(() => {
      document.getElementById('submenu').removeAttribute('style');
    }, 500);
  }

  blurContent(): void {
    this.store.dispatch({
      type: storeActions.BLUR_CONTENT
    });
  }

  unblurContent(): void {
    this.store.dispatch({
      type: storeActions.UNBLUR_CONTENT
    });
  }

  invertColors(): void {
    this.store.dispatch({
      type: storeActions.INVERT_COLORS
    });
  }

  onRedirect(page): void {
    if (page === 'projects' || page === 'project') {
      //console.log('hide' + page);
      this.hideSubmenu();
    }
  }
}
