<div id="bio-wrapper">
  <div class="bio-header">
    <img src="assets/x_in_box.svg" (click)="hideBio()">
    <span>BIO</span>
  </div>
  <div class="bio-content">
    <span class="placeholder"></span>
    <span class="content">
      DJ and music producer based in Oslo, NO.
      His family came from Ghana and the African
      origins play an important role in his creative
      process. Steven is also involved in personal
      projects, aimed to showcase the problem of
      racism in Norway and collaborates with artists.
    </span>
    <span class="placeholder"></span>
  </div>
</div>
