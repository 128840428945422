<div class="left-placeholder"></div>
<div class="full-contact">
  <div class="contact-wrapper">
    <div class="phone-number-wrapper contact-info">
      <label>PHONE</label>
      <span id="phone-number">{{phoneNumber}}</span>
    </div>
    <div class="email-wrapper contact-info">
      <label>EMAIL</label>
      <span>{{email}}</span>
    </div>
  </div>
  <div class="contact-label">
    FOR INQUIRIES AND COLLABORATIONS
  </div>
</div>
<div class="right-placeholder"></div>
