<div class="left-placeholder"></div>
<div class="full-music">
  <div class="music-wrapper">
    <a href="#">
      <img src="assets/music/apple_music.png">
    </a>
    <a href="#">
      <img src="assets/music/spotify.png">
    </a>
    <a href="#">
      <img src="assets/music/google_play_music.png">
    </a>
  </div>
  <div class="music-label">
    MORE TRACKS, SETS & STREAMS
  </div>
</div>
<div class="right-placeholder"></div>
