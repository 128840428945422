import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CloudService {
  files: any = [
    {
      url: environment.pageUrl + 'assets/audio/crazy.mp3',
      name: 'Crazy',
      runtime: '3:40'
    },
    { url: environment.pageUrl + 'assets/audio/enough-of-you.mp3',
      name: 'Enough off you',
      runtime: '3:55'
    },
    { url: environment.pageUrl + 'assets/audio/love-me-right-back.mp3',
      name: 'Love Me Right Back',
      runtime: '2:48'
    },
    { url: environment.pageUrl + 'assets/audio/say-it-right.mp3',
      name: 'Say It Right',
      runtime: '3:47'
    },
    { url: environment.pageUrl + 'assets/audio/touch.mp3',
      name: 'Touch',
      runtime: '3:30'
    }
  ];

  getFiles() {
   return of(this.files);
  }
}
