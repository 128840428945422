import {Component, ComponentFactoryResolver, Input, OnInit} from '@angular/core';
import * as projects from '../data/projects.json';
import {tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/appState';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  host: {'style': 'width:100%;'}
})
export class ProjectsComponent implements OnInit {
  public projectsData = (projects as any).default;
  public thirdColumnWidth: number;
  public hasBluredClass: boolean = false;
  public height = '';

  public isBlured$: Observable<any>;

  constructor(private store: Store<{myStore: AppState}>) { }

  ngOnInit(): void {
    this.setObservers();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const windowWidth = window.innerWidth;
      const tabletBreakpoint = 1300;
      const mobileBreakpoint = 768;

      let newWidth = document.querySelector('nav span.label').clientWidth;

      if (windowWidth <= mobileBreakpoint) {
        newWidth = windowWidth;
      } else if (windowWidth <= tabletBreakpoint) {
        newWidth = newWidth * 2;
      }

      this.thirdColumnWidth = newWidth;
    });
  }

  private setObservers() {
    this.isBlured$ = this.store.select('myStore', 'blur').pipe(tap(data => this.hasBluredClass = data));

    this.isBlured$.subscribe();
  }
}
