<nav id="nav-navbar">
  <span id="hamburger" (click)="showBurgerMenu()">
    <span></span>
  </span>
  <div class="center-column-wrapper">
    <ul class="main-menu" style="visibility: hidden;">
      <li class="main-menu-item projects" (mouseover)="blurContent()" (mouseout)="unblurContent()">PROJECTS
        <ul class="submenu projects" id="submenu">
          <ng-container *ngFor="let project of projectsData">
            <li *ngIf="project.showInMenu" (click)="redirectToProject(project.id)">
              <span class="name">{{project.name}}</span>
              <span class="year">{{project.year}}</span>
              <span class="status">{{project.status}}</span>
            </li>
          </ng-container>
          <li (click)="emitNavEvent('projects')">SHOW MORE PROJECTS</li>
        </ul>
      </li>
<!--      <li class="main-menu-item" (click)="emitNavEvent('shop')">SHOP</li>-->
      <li class="main-menu-item" (click)="emitNavEvent('music')">MUSIC</li>
      <li class="main-menu-item" (click)="emitNavEvent('contact')">CONTACT</li>
    </ul>

    <div class="black-white-swithcer-wrapper"
         (click)="toggleInverted()">
      <img src="assets/navbar/B-W-hover.svg" class="hover">
      <img src="assets/navbar/B-W.svg" class="regular">
    </div>
  </div>
  <span class="label">STEVEN BAKES</span>
</nav>

<div class="burger-menu">
  <div class="column">
    <div class="status-bar">
<!--      <img src="assets/big_x.svg" (click)="hideBurgerMenu()">-->
      <img src="assets/big_x-gold.svg" class="gold" (click)="hideBurgerMenu()">
    </div>
    <div class="navigation">
      <div class="left-placeholder"></div>
<!--      <ul class="side-menu desktop">-->
<!--        <li class="" (click)="emitNavEvent('projects')">PROJECTS</li>-->
<!--        <li class="" (click)="emitNavEvent('shop')">SHOP</li>-->
<!--        <li class="" (click)="emitNavEvent('music')">MUSIC</li>-->
<!--        <li class="" (click)="emitNavEvent('contact')">CONTACT</li>-->
<!--      </ul>-->
      <ul class="side-menu mobile">
        <li class="" (click)="emitNavEvent('home')">HOME</li>
        <li class="" (click)="emitNavEvent('projects')">PROJECTS</li>
        <li class="" (click)="emitNavEvent('music')">MUSIC</li>
        <li class="" (click)="emitNavEvent('contact')">CONTACT</li>
      </ul>
    </div>
    <div class="social-links-pointer">
      <div class="marquee" [ngStyle]="{'width.px': marqueeWidth}">
        <div class="marquee-value" [ngStyle]="{'max-width.px': marqueeWidth}">
          <ng-marquee speed="slow">
            &nbsp;&nbsp;&nbsp;SOCIAL MEDIA LINKS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOCIAL MEDIA LINKS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOCIAL
            MEDIA LINKS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOCIAL MEDIA LINKS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOCIAL
            MEDIA LINKS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOCIAL MEDIA LINKS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOCIAL
            MEDIA LINKS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOCIAL MEDIA LINKS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOCIAL
            MEDIA LINKS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOCIAL MEDIA LINKS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOCIAL
            MEDIA LINKS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOCIAL MEDIA LINKS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOCIAL
            MEDIA LINKS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOCIAL MEDIA LINKS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOCIAL
            MEDIA LINKS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOCIAL MEDIA LINKS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOCIAL
            MEDIA LINKS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOCIAL MEDIA LINKS&nbsp;&nbsp;&nbsp;
          </ng-marquee>
        </div>
      </div>
      <span class="social-links-pointer-arrow"><img src="assets/arrow-right.svg"></span>
    </div>
  </div>
  <div class="right-placeholder"></div>
</div>
