import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import {PlayerComponent} from './pages/website/player/player.component';
import {WebsiteComponent} from './pages/website/website.component';
import { ButtonComponent } from './pages/shared/button/button.component';
import { NavbarComponent } from './pages/website/navbar/navbar.component';
import {SocialLinksComponent} from './pages/website/social-links/social-links.component';
import {BioComponent} from './pages/website/bio/bio.component';
import {AvatarComponent} from './pages/website/avatar/avatar.component';
import {ContentDirective} from './pages/website/content.directive';
import {NgMarqueeModule} from 'ng-marquee';
import {ProjectsComponent} from './pages/website/projects/projects.component';
import {ProjectThumbnailComponent} from './pages/website/projects/project-thumbnail/project-thumbnail.component';
import { StoreModule } from '@ngrx/store';
import {storeReducer} from './store/store.reducer';
import { NgxSpinnerModule } from 'ngx-spinner';
import {GalleryComponent} from './pages/website/gallery/gallery.component';
import {PlayerMobileComponent} from './pages/website/player-mobile/player.component';
import {UnescapePipe} from './unescape.pipe';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';


@NgModule({
  declarations: [
    AppComponent,
    PlayerComponent,
    PlayerMobileComponent,
    WebsiteComponent,
    ButtonComponent,
    NavbarComponent,
    SocialLinksComponent,
    BioComponent,
    AvatarComponent,
    ContentDirective,
    ProjectsComponent,
    ProjectThumbnailComponent,
    GalleryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgMarqueeModule,
    StoreModule.forRoot({myStore: storeReducer}),
    StoreDevtoolsModule.instrument({
      name: 'NgRx Demo App'
    }),
    NgxSpinnerModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
