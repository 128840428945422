import { Component, OnInit } from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/appState';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  host: {'style': 'width:100%; margin-top:-101px;'}
})
export class GalleryComponent implements OnInit {
  public imageObject: Array<object> = [{
    image: 'assets/gallery/1.jpg'
  }, {
    image: 'assets/gallery/2.jpg'
  }, {
    image: 'assets/gallery/3.jpg'
  }, {
    image: 'assets/gallery/4.jpg'
  }, {
    image: 'assets/gallery/5.jpg'
  }, {
    image: 'assets/gallery/6.jpg'
  }, {
    image: 'assets/gallery/7.jpg'
  }, {
    image: 'assets/gallery/8.jpg'
  }
  ];

  public hasBluredClass: boolean = false;

  public isBlured$: Observable<any>;

  constructor(private store: Store<{myStore: AppState}>) {
  }

  ngOnInit(): void {
    this.setObservers();
  }

  private setObservers() {
    this.isBlured$ = this.store.select('myStore', 'blur').pipe(tap(data => this.hasBluredClass = data));

    this.isBlured$.subscribe();
  }
}
