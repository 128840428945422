import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss']
})
export class SocialLinksComponent implements OnInit {
  @Input() borderTop: boolean;

  links = [
    {
      link: 'soundcloud',
      icon: 'assets/social/soundcloud.svg',
      iconHover: 'assets/social/soundcloud.svg',
      url: 'https://m.soundcloud.com/user-907956635'
    },
    {
      link: 'youtube',
      icon: 'assets/social/youtube.svg',
      iconHover: 'assets/social/youtube.svg',
      url: 'https://www.youtube.com/channel/UC_rXM2Nro6AXm4FHyTsWiWA/search'
    },
    {
      link: 'spotify',
      icon: 'assets/social/spot.png',
      iconHover: 'assets/social/spot.png',
      url: 'https://open.spotify.com/artist/0NjlKoEAt6bcWuBxslxMKn?si=uVq8sXCBSaaOlxLPmnsXyg& nd=1'
    },
    {
      link: 'insta',
      icon: 'assets/social/instagram.svg',
      iconHover: 'assets/social/instagram.svg',
      url: 'https://www.instagram.com/st3phenofficial/'
    },
    {
      link: 'tiktok',
      icon: 'assets/social/tiktok.png',
      iconHover: 'assets/social/tiktok.png',
      url: 'https://www.tiktok.com/@stevenbakes'
    },
  ];
  //
  // soundcloud:https://m.soundcloud.com/user-907956635
  //
  // youtubehttps://www.youtube.com/channel/UC_rXM2Nro6AXm4FHyTsWiWA/search
  //
  //   spotifyhttps://open.spotify.com/artist/0NjlKoEAt6bcWuBxslxMKn?si=uVq8sXCBSaaOlxLPmnsXyg& nd=1
  //
  // instagramhttps://www.instagram.com/st3phenofficial/
  //
  //   tiktok https://vm.tiktok.com/ZMNMKgd5U/

  constructor() {
  }

  ngOnInit(): void {
  }

}
