import { Component, OnInit } from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/appState';
import {storeActions} from '../../../store/store.actions';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  constructor(private store: Store<{myStore: AppState}>) { }

  ngOnInit(): void {
  }

  openGallery(): void {
    this.store.dispatch({
      type: storeActions.REDIRECT,
      page: 'gallery',
      projectId: 0,
    });
  }
}
