import {createAction, props} from '@ngrx/store';

export const storeActions = {
  BLUR_CONTENT: '[Component] blur',
  UNBLUR_CONTENT: '[Component] unblur',
  INVERT_COLORS: '[Component] invert colors',
  CHANGE_NAV_HEIGHT: '[Component] change nav height',
  CHANGE_FOOTER_HEIGHT: '[Component] change footer height',
  REDIRECT: '[Component] redirect',
  HIDE_BIO: '[Component] hide bio',
  SHOW_BIO: '[Component] show bio',
  SHOW_MOBILE_PROJECT: '[Component] show mobile project',
  HIDE_MOBILE_PROJECT: '[Component] hide mobile project',
  CLEAR_CURRENT_PAGE: 'CLEAR CURRENT PAGE'
};

export const blur = createAction(storeActions.BLUR_CONTENT);
export const unblur = createAction(storeActions.UNBLUR_CONTENT);
export const invertColors = createAction(storeActions.INVERT_COLORS);
export const changeNavHeight = createAction(storeActions.CHANGE_NAV_HEIGHT, props<{height: number}>());
export const changeFooterHeight = createAction(storeActions.CHANGE_FOOTER_HEIGHT, props<{height: number}>());
export const makeRedirect = createAction(storeActions.REDIRECT, props<{page: string; projectId: number}>());
export const clearCurrentPage = createAction(storeActions.CLEAR_CURRENT_PAGE);
export const hideBio = createAction(storeActions.HIDE_BIO);
export const showBio = createAction(storeActions.SHOW_BIO);
export const hideMobileProject = createAction(storeActions.HIDE_MOBILE_PROJECT);
export const showMobileProject = createAction(storeActions.SHOW_MOBILE_PROJECT);
